<template lang="pug">
	.main-wrapper.funcionario-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Usuários</b>

		Dialog.dialogApagar(header='Remover usuário' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o usuário <b>{{ dialogApagar_data.name }} {{ dialogApagar_data.last_name }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove(dialogApagar_data.id)')

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-6
					label.form-label Nome:
					.p-inputgroup
						InputText( @keyup.enter.native='applyFilters()' v-model='filters.name'
							@keyup='checkEmptyField("name")' )
						Button(icon='jam jam-search' @click='applyFilters()')
				.p-col-12.p-md-6
					label.form-label Status:
						Dropdown(
							v-model='filters.ie_status'
							:options='options.ieStatus'
							optionLabel='text'
							optionValue='value'
							dataKey='value'
							placeholder='TODOS'
							@change='applyFilters()'
						)

		.ta-right.mt-4.mb-3
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/revendedor/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.is_active }")
						Panel.panel-list.ta-center(:header="`${ props.data.name } ${ props.data.last_name }`")
							.ta-left
								p <b>Nome:</b> {{ props.data.first_name }}
								p <b>Sobrenome:</b> {{ props.data.last_name }}
								p <b>E-mail:</b> {{ props.data.email }}
								p <b>Usuário:</b> {{ props.data.username }}
							.ta-right.p-buttonset.mt-3
								Button.mr-1.p-button-raised.p-button-rounded(
									icon='jam jam-write'
									@click="$router.push(`/revendedor/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-if='props.data.is_active' icon='jam jam-minus-circle'
									@click="dialogApagar_data = props.data; dialogApagar = true"
								)
			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de usuário')
				DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
					Column(headerStyle='width: 20%;' field='name' header='Nome' sortable)
						template(#body='props')
							span {{ props.data.first_name}} {{ props.data.last_name}}
					//- Column(headerStyle='width: 20%;' field='last_name' header='Sobrenome' sortable)
					Column(headerStyle='width: 20%;' field='username' header='Usuário' sortable)
					Column(headerStyle='width: 30%;' field='email' header='E-mail' sortable)
						template(#body='props')
							span(v-tooltip.top="props.data.email" style='cursor: pointer;') {{ props.data.email_f }}
					//- Column(headerStyle='width: 20%;' field='estabelecimentoauthuser__ds_setor' header='Setor' sortable)
					//- 	template(#body='props') {{ props.data.ds_setor }}
					Column(headerStyle='width: 10%; text-align: center')
						template(#header) Ações
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/revendedor/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar_data = props.data; dialogApagar = true"
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.funcionario-listar {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 16px 0;
				text-align: center;
				&.ex  {  background-color: #e4f8e1; }
				&.a  { background-color: #faf3dd; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
		.disabled {
			p { color: #aaa; }
			.p-panel-titlebar {
				span { color: #aaa; }
			}
			.p-panel-content {
				background-color: #f4f4f4 !important;
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Button from 'primevue/button'
	import ProgressBar from 'primevue/progressbar'
	import Dialog from 'primevue/dialog'
	import Tooltip from 'primevue/tooltip'
	import InputText from 'primevue/inputtext'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import Paginator from 'primevue/paginator'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dropdown from 'primevue/dropdown'

	import { Empresa, Funcionario } from './../../middleware'
	import wsConfigs from './../../middleware/configs'
	export default {
		created () {
			this.applyFilters()
		},
		components: {
			DataView, Panel, Button, ProgressBar, Dialog, InputText,
				Paginator, DataTable, Column, Tooltip, ProgressSpinner,
				Dropdown
		},
		directives: { tooltip: Tooltip },
		data () {
			return {
				list: [],
				dialogApagar: false,
                windowInnerWidth: window.innerWidth,
				dialogApagar_data: {},
				waiting: false,
				waitingApagar: false,
				filters: {
					name: '',
					ie_status: 'true'
				},
				options: {
					ieStatus:[
						{ text: "TODOS", value: null },
                        { text: "Ativo", value: 'true' },
                        { text: "Inativo", value: 'false' },
					]
				},
				paginator: {
					page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
                order: {
                    field: 'first_name',
                    sortOrder: 1
                }
			}
		},
		methods: {
			getList (params) {
				let vm = this
				vm.waiting = true
				// console.log(params);
				Empresa.listRevendedores(params).then(response => {
					if(response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(funcionario => {
							if (funcionario.email) {
								let strMaxLength = 44
								funcionario.email_f = funcionario.email.length > strMaxLength ?
									`${ funcionario.email.substr(0, strMaxLength) }...` : funcionario.email
							}
						})
						vm.list = response.data.results
					}
					vm.waiting = false
				})
				/*
				Funcionario.findAll(params).then(response => {
					if (response.status == 200) {
						this.paginator.count = response.data.count
						response.data.results.forEach(funcionario => {
							if (funcionario.email) {
								let strMaxLength = 44
								funcionario.email_f = funcionario.email.length > strMaxLength ?
									`${ funcionario.email.substr(0, strMaxLength) }...` : funcionario.email
							}
						})
						vm.list = response.data.results
					}
					vm.waiting = false
				})
				*/
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
                params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key] })
				this.getList(params)
			},
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
            onSort(ev) {
                this.order.field = ev.sortField
				this.order.sortOrder = ev.sortOrder
				this.applyFilters()
            },
			remove (id) {
				this.waitingApagar = true
				Funcionario.remove(id).then(response => {
					this.waitingApagar = false
					if (response.status == 200) {
						this.$toast.info('Usuário removido', { duration: 3000 })
						this.applyFilters()
						this.dialogApagar = false
					}
				})
			}
		}
	}
</script>